<template>
  <tr v-if='isVisible' class='short-cell'>
    <td class=''><div class='ml-12'>{{accountTypeName}}</div></td>
    <td class='border-l text-right'></td>
    <td class='border-l text-right'></td>
    <td class='border-l text-right'></td>
    <td class='border-l text-right'></td>
    <td class='border-l text-right'></td>
    <td class='border-l text-right'></td>
    <td class='border-l text-right'></td>
    <td class='border-l text-right text-sm'>{{numberStyle(detail.allocated_quantity)}}</td>
    <td class='border-l text-right text-sm'>{{numberStyle(detail.allocated_amount)}}</td>
    <td class='border-l text-right text-sm'>{{allocatedRate}}</td>
    <td class='border-l text-right text-sm'>{{numberStyle(detail.position_quantity)}}</td>
    <td class='border-l text-right text-sm'>{{numberStyle(detail.position_close_value)}}</td>
    <td class='border-l text-right'>
      <vue-numeric
        v-model='averageSellingPrice'
        @change='updateIpoSummaryAveragePrice'
        output-type='number'
        class='form-input text-right'
        style='height: 2rem; width: 6rem; font-size: 14px;'
        empty-value=''
        currency-symbol-position='suffix'
        separator=',' />
    </td>
    <td class='border-l text-right'>{{numberStyle(recoveryGainLoss)}}</td>
    <td class='border-l text-right'>{{recovery}}</td>
    <td class='border-l text-right'>{{earningsRatio}}</td>
    <td class='border-l text-right'>{{detail.lockup_expiration_date}}</td>
    <td class='border-l'>
      <textarea
        v-model='note'
        @change='updateIpoSummaries'
        rows='1'
        class='form-textarea leading-tight text-base lg:text-sm'
        style='height: 2rem; width: 16rem;'></textarea>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'IpoPortfolioDetails',
  props: [
    'ipo',
    'detail',
    'allocatedPrice',
    'isVisible',
  ],
  data () {
    return {
      averageSellingPrice: 0,
      note: '',
    }
  },
  watch: {
    'detail': {
      handler: function (newVal) {
        if (newVal) {
          this.averageSellingPrice = this.detail.average_selling_price
          this.note = this.detail.note
        }
      },
      immediate: true
    },
  },
  computed: {
    baseAllocatedQuantity () {
      if (!this.detail)
        return 0

      if (this.detail.account_type === '집합')
        return this.ipo.details['기관배정주식수'] ? parseFloat(this.ipo.details['기관배정주식수']) : 0

      if (this.detail.account_type === '벤처')
        return this.ipo.details['총공모주식수'] ? parseFloat(this.ipo.details['총공모주식수']) * 0.3 : 0

      if (this.detail.account_type === '하이일드')
        return this.ipo.details['총공모주식수'] ? parseFloat(this.ipo.details['총공모주식수']) * 0.05 : 0

      if (this.detail.account_type === '고유')
        return this.ipo.details['기관배정주식수'] ? this.ipo.details['기관배정주식수'] : 0

      return 0
    },
    allocatedRate () {
      return (this.detail.allocated_quantity === 0 || this.baseAllocatedQuantity === 0) ? '' : `${(this.detail.allocated_quantity / this.baseAllocatedQuantity * 100).toFixed(3)} %`
    },
    accountTypeName () {
      return this.detail.account_type
    },
    recoveryGainLoss () {
      if (this.detail.allocated_quantity === 0) {
        return 0
      } else {
        return this.averageSellingPrice !== 0 ? (this.averageSellingPrice - this.allocatedPrice) * (this.detail.allocated_quantity - this.detail.position_quantity) : 0
      }
    },
    recovery () {
      return this.detail.allocated_quantity === 0 ? '' : `${((this.detail.allocated_quantity - this.detail.position_quantity) / this.detail.allocated_quantity * 100).toFixed(1)} %`
    },
    earningsRatio () {
      return (this.recoveryGainLoss === 0 || this.detail.allocated_quantity === 0) ? '' : `${(this.recoveryGainLoss / this.detail.allocated_amount * 100).toFixed(1)} %`
    },
  },
  methods: {
    ...mapActions('ipos', [
      'patchIpoSummaries',
    ]),
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    updateIpoSummaries () {
      if (event) {
        this.patchIpoSummaries( {ipoId: this.ipo.id, accountType: this.accountTypeName, summary: this.detail, key: 'note', note: this.note} )
      }
    },
    updateIpoSummaryAveragePrice () {
      if (event) {
        this.patchIpoSummaries( {ipoId: this.ipo.id, accountType: this.accountTypeName, summary: this.detail, key: 'average_selling_price', average_selling_price: this.averageSellingPrice} )
      }
    },
  },
}
</script>

<style lang='scss' scoped>
  td {
    @apply border-b text-gray-700 whitespace-no-wrap;
  }

</style>
